<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="形象单元" prop="scheduleUnitName">
            <el-input v-model="dataForm.scheduleUnitName" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="计划年月" prop="ym">
            <el-date-picker style="width: 100%" v-model="dataForm.ym" format="yyyy年MM月"
                            value-format="yyyy-MM-dd" type="month" placeholder="请选择计划年月">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开始日期" prop="startDate">
            <el-date-picker style="width: 100%" v-model="dataForm.startDate" :format="dateFormat"
                            value-format="yyyy-MM-dd" type="date" placeholder="请选择开始日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束日期" prop="endDate">
            <el-date-picker style="width: 100%" v-model="dataForm.endDate" :format="dateFormat"
                            value-format="yyyy-MM-dd" type="date" placeholder="请选择结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="月计划工程量" prop="num">
            <el-input-number v-model="dataForm.num" :min='0' :data-unit="dataForm.unitName" placeholder="月计划工程量" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="月计划金额" prop="amount">
            <el-input-number v-model="dataForm.amount" :min='0' data-unit="元" placeholder="月计划金额" :controls="false" style="width: 100%;"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        scheduleUnitId: '',
        startDate: '',
        endDate: '',
        y: '',
        m: '',
        num: '',
        amount: '',
        scheduleUnitName: '',
        ym: '', // 计划年月
        unitName: ''
      },
      dateFormat: 'yyyy-MM-dd'
    }
  },
  computed: {
    dataRule () {
      return {
        ym: [
          {type: 'string',required: true,message: '请选择计划年月',trigger: 'change'}
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/monthlySchedule/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
        if (this.dataForm.y && this.dataForm.m) {
          this.dataForm.ym = this.dataForm.y +'-'+ this.dataForm.m + '-01'
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }

        // 根据计划年月，重新计算月计划的年份、月份
        if(this.dataForm.ym){
          let ymdList=this.dataForm.ym.split('-')
          this.dataForm.y=ymdList[0]
          this.dataForm.m=ymdList[1]
        }

        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/monthlySchedule/',this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    },1000,{'leading': true,'trailing': false})
  }
}
</script>