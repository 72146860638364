<template>
  <div>
    <el-dialog v-dialog-drag width="70%" :visible.sync="visible" :title="$t('add')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="计划年月" prop="ym">
              <el-date-picker v-model="dataForm.ym" :format="dateFormat"
                              value-format="yyyy-MM-dd" type="month" placeholder="请选择计划年月">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <vxe-table id="table" keep-source border
                 ref="tableRef" size="mini" show-overflow
                 :column-config="{resizable: true}"
                 :row-config="{isCurrent: true,isHover: true}"
                 :data="dataList"
                 :max-height="tableHeight"
                 :edit-rules="tableRules"
                 :edit-config="{trigger: 'click', mode: 'row',showStatus:true}">
        <vxe-column type="seq" title="序号" width="80" align="center" fixed="left">
          <template v-slot:header>
            <vxe-button type="button" status="primary" content="添加" round @click="batchAdd()"></vxe-button>
          </template>
        </vxe-column>
        <vxe-column field="name" title="形象单元" min-width="180" fixed="left"></vxe-column>
        <vxe-column field="unitName" title="单位" align="center" width="90" fixed="left"></vxe-column>
        <vxe-column field="num" title="计划工程量" align="right" header-align="center" :edit-render="{name: '$input', props: {type: 'number', controls: false}}" width="150" fixed="left"></vxe-column>
        <vxe-column field="amount" title="计划金额(元)" align="right" header-align="center" :edit-render="{name: '$input', props: {type: 'number', controls: false}}" width="150"></vxe-column>
        <vxe-column field="startDate" title="开始日期" align="center" :edit-render="{name: '$input', props: {type: 'date'}}" width="120"></vxe-column>
        <vxe-column field="endDate" title="结束日期" align="center" :edit-render="{name: '$input', props: {type: 'date'}}" width="120"></vxe-column>
        <vxe-column title="操作" width="80" align="center" fixed="right">
          <template #default="{row}">
            <el-button type="text" size="small" @click="deleteOneHandle(row)">{{ $t('delete') }}</el-button>
          </template>
        </vxe-column>
      </vxe-table>
      <template slot="footer">
        <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
      </template>
    </el-dialog>
    <scheduleunit-multi-select v-if="batchSelectVisible" ref="batchSelect" @submit="addDataToList" @close="unitBatchSelectDialogClose"></scheduleunit-multi-select>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        scheduleUnitId: '',
        startDate: '',
        endDate: '',
        y: '',
        m: '',
        num: '',
        amount: '',
        ym: '',
        prjId: ''
      },
      dateFormat: 'yyyy年MM月',
      tableRules: {},
      dataList: [],
      tableHeight: 400,

      // 形象单元选择
      batchSelectVisible: false,
      // 缓存已有的形象单元id列表，避免新增时重复添加
      existIdList: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      //  表格高度设置
      this.tableHeight = window.innerHeight - 280
    })
  },
  computed: {
    dataRule() {
      return {
        ym: [
          {type: 'string', required: true, message: '请选择月份', trigger: 'change'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
    },

    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        const loading = this.$loading({
          lock: true,
          text: '保存中...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        // 重新组装数据
        let y = ''
        let m = ''
        if (this.dataForm.ym) {
          let ym = this.dataForm.ym.split('-')
          y = ym[0]
          m = ym[1]
        }
        let dataListTemp = []
        this.dataList.forEach(function (obj) {
          // 计划开始、结束日期、计划量，计划金额有一项填写，则认为是有效数据
          if (obj.startDate || obj.endDate || obj.num || obj.amount) {
            dataListTemp.push(obj)
          }
        })
        let contractId = this.dataForm.contractId
        dataListTemp.forEach(function (obj) {
          obj.scheduleUnitId = obj.id
          obj.id = ''
          obj.contractId = contractId
          obj.y = y
          obj.m = m
        })
        this.dataList = dataListTemp

        this.$http['post']('/opm/monthlySchedule/batchSave', this.dataList).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList', {year: y, month: m})
            }
          })
        }).catch(() => {
          // do nothing
        }).finally(() => {
          loading.close()
        })
      })
    }, 1000, {'leading': true, 'trailing': false}),

    deleteOneHandle(row) {
      for (let index in this.existIdList) {
        if (this.existIdList[index] == row.id) {
          this.existIdList.splice(index, 1)
          break;
        }
      }
      this.$refs.tableRef.remove(row)
      this.dataList = this.$refs.tableRef.getTableData().fullData
    },

    // 打开多选弹窗
    batchAdd() {
      this.batchSelectVisible = true
      this.$nextTick(() => {
        this.$refs.batchSelect.dataForm.prjId = this.dataForm.prjId
        this.$refs.batchSelect.existIdList = this.existIdList
        this.$refs.batchSelect.init()
      })
    },

    // 批量选择形象单元的回调方法
    addDataToList(result) {
      this.dataList.push(...result)
      // 将选择的数据id添加到已存在的列表中
      this.existIdList.push(...(result.map((obj) => obj.id)))
    },

    unitBatchSelectDialogClose() {
      this.batchSelectVisible = false
    },


  }
}
</script>