<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__predrawingcatalog">
      <my-container :leftWidth="300">
        <template v-slot:left>
          <el-form inline size="mini">
            <el-form-item>
              <pre-project-select width="280px" v-model="dataForm.prePrjId" placeholder="请选择项目" :defaultSelect="true" @change="preprojectSelectHandle"></pre-project-select>
            </el-form-item>
          </el-form>
          <div class="tree-container">
            <vxe-table
                height="auto"
                ref="vxeTree"
                show-overflow
                :show-header="false"
                :loading="treeLoading"
                :tree-config="treeConfig"
                :row-config="treeRowConfig"
                :menu-config="treeMenu"
                :data="treeData"
                :edit-config="treeEditConfig"
                @cell-click="treeCellClick"
                @cell-menu="cellContextMenuEvent"
                @menu-click="contextMenuClickEvent">
              <vxe-column tree-node field="name" title="图纸目录" width="300" :edit-render="{name: '$input', events: {change: nameChange}}"></vxe-column>
            </vxe-table>
          </div>
        </template>
        <template v-slot:right>
          <template v-if="isShowUpload()">
            <my-upload ref="uploader" :refId="currentSelectRow.id" typeId="普通附件" :view="!canEdit" :tableMaxHeight="tableHeight"></my-upload>
          </template>
          <template v-else>
            <el-alert :title="rightTitle" type="info" :closable="false"></el-alert>
          </template>
        </template>
      </my-container>
    </div>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      dataForm: {
        prePrjId: '',
      },
      treeData: [],
      treeConfig: {
        transform: true,
        rowField: 'id',
        parentField: 'pid',
        lazy: true,
        hasChild: 'hasChildren',
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus',
        loadMethod: this.loadChildrenMethod
      },
      treeRowConfig: {
        isCurrent: true,
        isHover: true,
        useKey: true,
        keyField: 'id'
      },
      treeEditConfig: {
        enabled: this.canEdit(),
        trigger: 'manual',
        mode: 'row',
      },
      treeLoading: false,
      treeMenu: { // 右键菜单配置
        body: {
          options: [
            [
              {code: 'add', name: '新增', prefixIcon: 'vxe-icon-add', visible: false},
              {code: 'update', name: '修改', prefixIcon: 'vxe-icon-edit', visible: false},
              {code: 'delete', name: '删除', prefixIcon: 'vxe-icon-delete', visible: false},
              {code: 'upRow', name: '上移', prefixIcon: 'vxe-icon-upload', visible: false},
              {code: 'downRow', name: '下移', prefixIcon: 'vxe-icon-download', visible: false},
            ]
          ]
        },
        visibleMethod: this.visibleMenu
      },
      currentSelectRow: null,
      tableHeight: 400
    }
  },
  created() {
    this.tableHeight = window.innerHeight - 200
  },
  computed: {
    rightTitle() {
      return this.canEdit() ? '鼠标[右键]可添加图纸目录' : '请选择图纸目录'
    }
  },
  methods: {
    // 前期项目选择
    preprojectSelectHandle(data) {
      this.treeData = []
      this.currentSelectRow = null
      this.getTreeData()
    },
    // 获取图纸目录
    getTreeData() {
      this.treeLoading = true
      // 虚拟根节点
      let rootData = {
        name: '图纸目录',
        id: '0',
        pid: '-1',
        prePrjId: this.dataForm.prePrjId,
        hasChildren: false
      }
      this.treeData.push(rootData)
      this.$http.get('/opm/preDrawingCatalog/children', {
        params: {
          pid: 0,
          prePrjId: this.dataForm.prePrjId,
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        }
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.treeData.push(res.data[i])
          }
          this.$nextTick(() => {
            const $table = this.$refs.vxeTree
            const rootRow = $table.getRowById(rootData.id)
            rootRow.hasChildren = true
            // 展开根节点
            $table.setTreeExpand(rootRow, true).then(() => {
              // 默认选中一个节点
              const row = $table.getRowById(res.data[0].id)
              $table.setCurrentRow(row).then(() => {
                this.currentSelectRow = row
              })
            })
          })
        }
      }).catch(() => {
        this.treeData = []
        this.$message.error('加载出错')
      }).finally(() => {
        this.treeLoading = false
      })
    },
    loadChildrenMethod({row}) {
      return this.findChildren(row.id)
    },
    findChildren(pid) {
      return new Promise((resolve, reject) => {
        this.$http.get(
            '/opm/preDrawingCatalog/children',
            {
              params: {
                pid: pid,
                prePrjId: this.dataForm.prePrjId,
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            reject()
            return this.$message.error(res.msg)
          }
          resolve(res.data)
        }).catch(() => {
          reject()
        })
      })
    },
    nameChange({row}, event) {
      // 因为vxe-table的内置$input渲染器会在一次修改中触发多次change事件，因此需要判断出原生的那一次change事件
      if (event.$event && event.$event.type == 'change') {
        if (event.value == '') {
          this.$message.error("图纸目录名称不能为空！")
          let previousName = row.name
          setTimeout(() => {
            row.name = previousName
            this.updateEvent(row)
          }, 100)
        } else {
          // 更新
          this.$http['put']('/opm/preDrawingCatalog/', {
            ...row,
            name: event.value
          }).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
          }).catch(() => {
            this.$message.error('出错了')
          })
        }
      }
    },
    isShowUpload() {
      // 图纸目录根节点不显示附件上传
      if (this.currentSelectRow && this.currentSelectRow.id != 0) {
        return true
      }
      return false
    },
    canEdit() {
      return this.$hasPermission('opm:predrawingcatalog:edit')
    },
    // 左侧树节点单元格的点击事件
    treeCellClick({row, triggerTreeNode}) {
      if (!triggerTreeNode) {
        this.currentSelectRow = row
      }
    },
    // 清除单元格激活状态
    clearEdit() {
      this.$refs.vxeTree.clearEdit()
    },
    cellContextMenuEvent({row}) {
      this.clearEdit()
      if (row) {
        // 显示右键菜单时，默认选中该图纸目录，用于刷新左侧的附件列表
        this.$refs.vxeTree.setCurrentRow(row)
        this.currentSelectRow = row
      }
    },
    visibleMenu({type, options, row}) {
      options.forEach(list => {
        list.forEach(item => {
          // 先隐藏右键菜单项
          item.visible = false
          if (this.canEdit()) {
            if (['add'].includes(item.code)) {
              if (row) {
                item.visible = true
              }
            } else if (['update'].includes(item.code)) {
              if (row && row.id != 0) {
                item.visible = true
              }
            } else if (['upRow'].includes(item.code)) {
              if (row && row.id != 0) {
                item.visible = true
              }
            } else if (['downRow'].includes(item.code)) {
              if (row && row.id != 0) {
                item.visible = true
              }
            }
          }
          if (['delete'].includes(item.code)) {
            if (this.$hasPermission('opm:predrawingcatalog:delete')) {
              if (row && row.id != 0) {
                item.visible = true
              }
            }
          }
        })
      })
      return true
    },
    // 右键菜单项点击事件
    contextMenuClickEvent({menu, row}) {
      if (row) {
        switch (menu.code) {
          case 'add':
            this.insertEvent(row)
            break
          case 'update':
            this.updateEvent(row)
            break
          case 'delete':
            this.deleteEvent(row)
            break
          case 'upRow':
            this.moveBackward(row)
            break
          case 'downRow':
            this.moveForward(row)
            break
        }
      }
    },

    async insertEvent(parentRow) {
      this.treeLoading = true
      const $table = this.$refs.vxeTree
      let pid = parentRow.id
      let record = {
        name: '新建目录',
        pid: pid,
        prePrjId: this.dataForm.prePrjId
      }
      try {
        // 获取排序序号
        const {data: sortRes} = await this.$http.get(`/opm/preDrawingCatalog/${pid}/newSortNo`)
        if (sortRes.code !== 0) {
          this.treeLoading = false
          return this.$message.error(sortRes.msg)
        }
        record.sortNo = sortRes.data
        // 保存数据
        const {data: saveRes} = await this.$http['post']('/opm/preDrawingCatalog/', record)
        if (saveRes.code !== 0) {
          this.treeLoading = false
          return this.$message.error(saveRes.msg)
        }
        record.id = saveRes.data

        // 重新展开父节点
        parentRow.hasChildren = true
        await $table.reloadTreeExpand(parentRow).then((rows)=>{
          this.$nextTick(() => {
            // 激活刚添加节点的编辑模式
            this.setEditCellAfterInsert(record.id, 10)// 最多尝试10次（每次间隔50毫秒，即最多尝试500毫秒）
          })
        })

        this.currentSelectRow = null
        this.treeLoading = false
      } catch (error) {
        this.treeLoading = false
        return this.$message.warning("出错了")
      }
    },

    setEditCellAfterInsert(id, attempts) {
      if (attempts > 0) {
        window.setTimeout(()=>{
          let _r = this.$refs.vxeTree.getRowById(id)
          if (_r) {
            this.updateEvent(_r)
          } else {
            this.setEditCellAfterInsert(id, --attempts)
          }
        }, 50) // 每50毫秒尝试一次，最多尝试attempts次
      }
    },

    async updateEvent(row) {
      if (row) {
        // 激活单元格编辑
        await this.$refs.vxeTree.setEditCell(row, 'name')
      }
    },

    deleteEvent(row) {
      if (row) {
        this.$confirm('同时会删除下级节点', '确定进行[删除]操作?', {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        }).then(() => {
          this.$http.delete('/opm/preDrawingCatalog', {'data': [row.id]}).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            // 删除关联的附件
            let ids = []
            this.getCascadeChildrenId(row, ids)
            this.$http.delete('/oss/file/deleteByRefIds', {'data': ids})

            this.$refs.vxeTree.remove(row)
            this.currentSelectRow = null
          }).catch(() => {
            return this.$message.error(res.msg)
          })
        }).catch()
      }
    },

    // 上移（即：前移）
    moveBackward(row) {
      this.$http.post('/opm/preDrawingCatalog/moveBackward', row).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        let $table = this.$refs.vxeTree;
        // 重新加载子节点数据
        let parentRow = $table.getRowById(row.pid)
        $table.reloadTreeExpand(parentRow)
        this.$message.success('移动成功')
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },

    // 下移（即：后移）
    moveForward(row) {
      this.$http.post('/opm/preDrawingCatalog/moveForward', row).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        let $table = this.$refs.vxeTree;
        // 重新加载子节点数据
        let parentRow = $table.getRowById(row.pid)
        $table.reloadTreeExpand(parentRow)
        this.$message.success('移动成功')
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },

    // 级联查询该节点的子孙节点id
    getCascadeChildrenId(row, list) {
      list.push(row.id)
      if (row.children && row.children.length > 0) {
        for (let i = 0; i < row.children.length; i++) {
          this.getCascadeChildrenId(row.children[i], list)
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 150px);
}
</style>